var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('SettingsTopBar',{attrs:{"pathCurrent":_vm.$t('Updates'),"pathBefore":_vm.$t('Settings /'),"showBackButton":true,"backRoute":_vm.backRoute,"showSubmitButton":false}}),(_vm.userHasRights)?_c('div',{staticClass:"wrapper"},[_c('h2',{staticClass:"u-a3"},[_vm._v(_vm._s(_vm.$t('Ručna ažuriranja')))]),_c('div',{staticStyle:{"margin-bottom":"30px"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.disableButtons),expression:"disableButtons"}],attrs:{"type":"checkbox","id":"disableButtons"},domProps:{"checked":Array.isArray(_vm.disableButtons)?_vm._i(_vm.disableButtons,null)>-1:(_vm.disableButtons)},on:{"change":function($event){var $$a=_vm.disableButtons,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.disableButtons=$$a.concat([$$v]))}else{$$i>-1&&(_vm.disableButtons=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.disableButtons=$$c}}}}),_c('label',{attrs:{"for":"disableButtons"}},[_vm._v(" Disable Buttons")])]),_vm._l((_vm.actions),function(action,index){return _c('AButton',{directives:[{name:"debounce",rawName:"v-debounce:300ms",value:(
                () => {
                    _vm.openConfirmUpdateProductFormPopup(action);
                }
            ),expression:"\n                () => {\n                    openConfirmUpdateProductFormPopup(action);\n                }\n            ",arg:"300ms"}],key:index,class:[
                'button',
                'u-a1--bold',
                { 'disabled-button': _vm.disableButtons && (index === 4 || index === 5 || index === 6) },
            ],attrs:{"disabled":action.isDisabled,"type":"primary"}},[_vm._v(" "+_vm._s(action.buttonLabel)+" ")])})],2):_c('NotAllowedComponent',{attrs:{"message":_vm.$t('You don’t have user rights for this module')}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }